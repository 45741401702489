var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"v-sheet theme--light br-0",attrs:{"elevation":1}},[_c('v-card-title',[_c('v-row',{staticClass:"col-md-12"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_vm._v(" Estrategias ")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-btn',{staticClass:"mb-2 float-right light-blue-502",attrs:{"color":"","dark":""},on:{"click":_vm.modalNuevo}},[_vm._v(" Registrar estrategia ")])],1)],1),_c('v-row',{staticClass:"col-md-12 m-0 p-0"},[_c('v-divider')],1)],1),_c('v-card-text',{staticClass:"pl-0 pr-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.filtro,"loading":_vm.tableItemsLoading,"no-data-text":"No se encontraron registros","loading-text":"Cargando...","header-props":{
                sortByText: 'Ordenar por'
            },"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","xs":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"off","dense":"","filled":"","color":"blue-grey lighten-2","label":"Nombre de la estragegia, fuente bilateral, agencia, fuente multilateral","maxlength":"200"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","xs":"6"}},[_c('v-select',{attrs:{"items":_vm.estados,"loading":false,"dense":"","filled":"","label":"Estado","no-data-text":'No existen estados registrados',"menu-props":"auto","return-object":""},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-col',{staticClass:"d-none",attrs:{"cols":"2","md":"2","sm":"12","xs":"6"}},[_c('v-btn',{staticClass:"ma-0 white--text d-none",attrs:{"medium":"","color":"light-blue darken-2"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Buscar ")],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.estrategia))]),_c('td',[_vm._v(_vm._s(item.tiposFuenteCooperanteId === 1 ? item.nombreAgencia ? item.nombreAgencia + ", " + item.fuenteBilateral : "" + item.fuenteBilateral : item.agenciaMultilateral ? item.agenciaMultilateral + ", " + item.fuenteBilateral : "" + item.fuenteMultilateral))]),_c('td',[_vm._v(_vm._s(item.nombreTipoFuente))]),_c('td',[_c('v-chip',{staticClass:"ma-2 font-weight-medium",attrs:{"label":"","color":item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : item.estadosId === 3
                      ? 'teal lighten-4'
                      : 'pink lighten-4',"text-color":item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : item.estadosId === 3
                      ? 'teal lighten-2'
                      : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[(item.estadosId === 1 || item.estadosId === 2)?_c('v-btn',{staticClass:"\n                    ma-2\n                    btn-bg-light\n                    blue-grey--text\n                    lighten-2--text\n                    font-weight-medium\n                    text-capitalize\n                  ",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.obtenerDatosItem(item.id, item.cooperante)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Actualizar ")],1):_vm._e(),(item.estadosId === 1)?_c('v-btn',{staticClass:"\n                    ma-2\n                    btn-bg-light\n                    blue-grey--text\n                    lighten-2--text\n                    font-weight-medium\n                    text-capitalize\n                  ",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.mostrarDialoglValidar(item.id, item.estrategia)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-all")]),_vm._v(" Validar ")],1):_vm._e()],1)])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"1280","transition":"scroll-y-transition","persistent":""},model:{value:(_vm.dialogRegistro),callback:function ($$v) {_vm.dialogRegistro=$$v},expression:"dialogRegistro"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.accion===2 ? ("Actualizar | " + _vm.nombreEstrategia) : "Registrar estrategia")+" "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
              _vm.resetForm();}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{staticClass:"d-none"}),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"pt-4 pl-2 pr-2",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.tiposFuenteCooperante,"loading":false,"disabled":_vm.deshabilitarFuentes,"dense":"","filled":"","label":"Tipo fuente cooperante","item-text":"nombreTipoFuente","item-value":"id","return-object":"","rules":[_vm.selectRequired('nombre del tipo fuente cooperante')],"no-data-text":_vm.tiposFuenteCooperante != null
                                ? 'Selecciona un tipo de fuente cooperante'
                                : 'No se han encontrado tipos de fuente cooperante',"menu-props":"auto"},model:{value:(_vm.tipoFuenteCooperanteSeleccionada),callback:function ($$v) {_vm.tipoFuenteCooperanteSeleccionada=$$v},expression:"tipoFuenteCooperanteSeleccionada"}})],1),(_vm.tipoFuenteCooperanteSeleccionada.id == 1)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{class:_vm.tipoFuenteCooperanteSeleccionada.id == 1 ? 'required' : '',attrs:{"items":_vm.fuentesBilaterales,"disabled":_vm.deshabilitarFuentes,"loading":false,"required":_vm.tipoFuenteCooperanteSeleccionada.id == 1,"dense":"","filled":"","label":"País fuente bilateral","item-text":"pais","item-value":"id","return-object":"","rules":[
                              _vm.tipoFuenteCooperanteSeleccionada.id === 1 ? _vm.selectRequired('país bilateral') : true ],"no-data-text":_vm.fuentesBilaterales != null
                              ? 'Selecciona un país'
                              : 'No se han encontrado países',"menu-props":"auto"},on:{"change":function($event){return _vm.obtenerAgenciasPorFuenteBilateral()}},model:{value:(_vm.fuenteBilateralSeleccionada),callback:function ($$v) {_vm.fuenteBilateralSeleccionada=$$v},expression:"fuenteBilateralSeleccionada"}})],1):_vm._e(),(_vm.tipoFuenteCooperanteSeleccionada.id == 1)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.agencias,"disabled":_vm.deshabilitarFuentes,"loading":_vm.ddAgenciasLoading,"dense":"","filled":"","label":"Seleccione agencia","item-text":"nombreAgencia","item-value":"id","return-object":"","no-data-text":_vm.fuentesBilaterales != null
                              ? 'Selecciona una agencia'
                              : 'No se han encontrado agencias',"menu-props":"auto"},model:{value:(_vm.agenciaSeleccionada),callback:function ($$v) {_vm.agenciaSeleccionada=$$v},expression:"agenciaSeleccionada"}})],1):_vm._e(),(_vm.tipoFuenteCooperanteSeleccionada.id == 2)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.fuentesMultilateral,"disabled":_vm.deshabilitarFuentes,"loading":false,"dense":"","filled":"","label":"Seleccione fuente multilateral","item-text":"nombreFuenteMultilateral","item-value":"id","return-object":"","no-data-text":_vm.fuentesMultilateral != null
                              ? 'Seleccione una fuente multilateral'
                              : 'No se han encontrado fuentes multilaterales',"menu-props":"auto"},model:{value:(_vm.fuenteMultilateralSeleccionada),callback:function ($$v) {_vm.fuenteMultilateralSeleccionada=$$v},expression:"fuenteMultilateralSeleccionada"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"autocomplete":"off","dense":"","rows":"2","filled":"","color":"blue-grey lighten-2","label":"Nombre de la estrategia","rules":[
                                _vm.required('nombre de la estrategia'),
                                _vm.minLength('nombre de la estrategia', 5),
                                _vm.maxLength('nombre de la estrategia', 500)
                            ],"maxlength":"500"},model:{value:(_vm.datosItem.estrategia),callback:function ($$v) {_vm.$set(_vm.datosItem, "estrategia", $$v)},expression:"datosItem.estrategia"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"autocomplete":"off","dense":"","rows":"2","filled":"","color":"blue-grey lighten-2","label":"Resultados de la estrategia","rules":[
                                _vm.required('resultados de la estrategia'),
                                _vm.minLength('resultados de la estrategia', 5),
                                _vm.maxLength('resultados de la estrategia', 500)
                            ],"maxlength":"500"},model:{value:(_vm.datosItem.resultados),callback:function ($$v) {_vm.$set(_vm.datosItem, "resultados", $$v)},expression:"datosItem.resultados"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Fecha de inicio","rules":[
                                _vm.required('fecha de inicio'),
                                _vm.minLength('fecha de inicio', 10),
                                _vm.dateFormat('fecha de inicio')
                            ],"maxlength":"10"},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Fecha de finalización","rules":[
                                _vm.required('fecha de finalización'),
                                _vm.minLength('fecha de finalización', 10),
                                _vm.dateFormat('fecha de finalización'),
                                _vm.dateEqualGreatherThan('fecha de finalización', _vm.fechaInicio)
                            ],"maxlength":"10"},model:{value:(_vm.fechaFinalizacion),callback:function ($$v) {_vm.fechaFinalizacion=$$v},expression:"fechaFinalizacion"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Monto en dólares","prefix":"USD","rules":[
                                _vm.required('monto en dólares'),
                                _vm.decimals10('monto en dólares')
                            ],"maxlength":"30"},model:{value:(_vm.montoMonedaUsd),callback:function ($$v) {_vm.montoMonedaUsd=$$v},expression:"montoMonedaUsd"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Tipo de cambio","rules":[
                                _vm.required('tipo de cambio'),
                                _vm.decimals10('tipo de cambio')
                            ],"maxlength":"30"},model:{value:(_vm.tipoCambio),callback:function ($$v) {_vm.tipoCambio=$$v},expression:"tipoCambio"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","disabled":true,"autocomplete":"off","color":"blue-grey lighten-2","label":"Monto total quetzales","maxlength":"30","prefix":"GTQ"},model:{value:(_vm.datosItem.montoQuetzales),callback:function ($$v) {_vm.$set(_vm.datosItem, "montoQuetzales", $$v)},expression:"datosItem.montoQuetzales"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text mb-2 float-right ml-2",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" "+_vm._s(_vm.accion===1 ? "Registrar estrategia" : "Actualizar estrategia")+" ")]),_c('v-btn',{staticClass:"mb-2 float-right grey lighten-5",attrs:{"color":"grey lighten-5","elevation":"0","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
                                _vm.resetForm();}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500","transition":"scroll-y-transition"},model:{value:(_vm.dialogValidacion),callback:function ($$v) {_vm.dialogValidacion=$$v},expression:"dialogValidacion"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Validar estrategia "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","disabled":_vm.btnValidacionLoading,"persistent":"","transition":"scroll-y-transition"},on:{"click":function($event){_vm.dialogValidacion = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h6 black--text"},[_vm._v(" "+_vm._s(_vm.estrategia)+" ")]),_c('p',{staticClass:"text-h6"},[_vm._v(" ¿Está seguro de validar la estrategia seleccionada? ")]),_c('p',{staticClass:"text-h6"},[_vm._v(" Una vez validada, ya no podrá actualizar su información! ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"blue-grey darken-2","text":"","loading":_vm.btnValidacionLoading,"type":"submit"},on:{"click":_vm.validarEstrategia}},[_vm._v(" Sí, validar ")]),_c('v-btn',{staticClass:"float-right",attrs:{"color":"blue-grey darken-2","text":"","disabled":_vm.btnValidacionLoading},on:{"click":function($event){_vm.dialogValidacion = false}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText,"transition":"scroll-y-transition"}}),_c('SnackAlert',{ref:"snackalert"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }