<template lang="">
 <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Estrategias
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color=""
              dark
              class="mb-2 float-right light-blue-502"
              @click="modalNuevo"
            >
              Registrar estrategia
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">


        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="items"
          :search="filtro"
          :loading="tableItemsLoading"
          no-data-text="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
                sortByText: 'Ordenar por'
            }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Nombre de la estragegia, fuente bilateral, agencia, fuente multilateral"
                    maxlength="200"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.estrategia }}</td>
              <td>{{ item.tiposFuenteCooperanteId === 1 ? item.nombreAgencia ? item.nombreAgencia + `, ` + item.fuenteBilateral : `` + item.fuenteBilateral : item.agenciaMultilateral ?  item.agenciaMultilateral + `, ` + item.fuenteBilateral : `` + item.fuenteMultilateral }}</td>
              <td>{{ item.nombreTipoFuente }}</td>
             <!-- <td>
                {{
                  item.nombreMaximaAutoridad != null
                    ? item.nombreMaximaAutoridad
                    : ""
                }}
              </td>-->
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : item.estadosId === 3
                      ? 'teal lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : item.estadosId === 3
                      ? 'teal lighten-2'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn v-if="item.estadosId === 1 || item.estadosId === 2"
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosItem(item.id, item.cooperante)"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>    

                <v-btn v-if="item.estadosId === 1"
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialoglValidar(item.id, item.estrategia)"
                >
                  <v-icon left>mdi-check-all</v-icon> Validar
                </v-btn>                     
              </td>
            </tr>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>


    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1280"
      transition="scroll-y-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          {{accion===2 ? `Actualizar | ${nombreEstrategia}` : `Registrar estrategia`}}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text>

            <v-form
                ref="form"
                v-on:submit.prevent="registrarItem"
                v-model="validForm"
                class="pt-4 pl-2 pr-2"
                >

                <v-row class="mt-4">
                  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                        <v-select
                        v-model="tipoFuenteCooperanteSeleccionada"
                            :items="tiposFuenteCooperante"
                            :loading="false"
                            :disabled="deshabilitarFuentes"
                            dense
                            filled
                            class="required"
                            label="Tipo fuente cooperante"
                            item-text="nombreTipoFuente"
                            item-value="id"
                            return-object
                            :rules="[selectRequired('nombre del tipo fuente cooperante')]"
                            :no-data-text="
                                tiposFuenteCooperante != null
                                ? 'Selecciona un tipo de fuente cooperante'
                                : 'No se han encontrado tipos de fuente cooperante'
                            "
                            menu-props="auto"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 1">
                        <v-select
                          v-model="fuenteBilateralSeleccionada"
                          :items="fuentesBilaterales"
                          :disabled="deshabilitarFuentes"
                          :loading="false"
                          :required="tipoFuenteCooperanteSeleccionada.id == 1"
                          dense
                          :class="tipoFuenteCooperanteSeleccionada.id == 1 ? 'required' : ''"
                          filled
                          label="País fuente bilateral"
                          item-text="pais"
                          item-value="id"
                          return-object
                          :rules="[
                              tipoFuenteCooperanteSeleccionada.id === 1 ? selectRequired('país bilateral') : true,
                          ]"
                          :no-data-text="
                              fuentesBilaterales != null
                              ? 'Selecciona un país'
                              : 'No se han encontrado países'
                          "
                          @change="obtenerAgenciasPorFuenteBilateral()"
                          menu-props="auto"
                        ></v-select>
                    </v-col>

                    <!-- inicio:: agencias -->
                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 1">
                        <v-select
                          v-model="agenciaSeleccionada"
                          :items="agencias"
                          :disabled="deshabilitarFuentes"
                          :loading="ddAgenciasLoading"
                          dense
                          filled
                          label="Seleccione agencia"
                          item-text="nombreAgencia"
                          item-value="id"
                          return-object
                          :no-data-text="
                              fuentesBilaterales != null
                              ? 'Selecciona una agencia'
                              : 'No se han encontrado agencias'
                          "
                          menu-props="auto"
                        ></v-select>
                    </v-col>
                    <!-- fin:: agencias -->

                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 2">
                        <v-select
                          v-model="fuenteMultilateralSeleccionada"
                          :items="fuentesMultilateral"
                          :disabled="deshabilitarFuentes"
                          :loading="false"
                          dense
                          filled
                          label="Seleccione fuente multilateral"
                          item-text="nombreFuenteMultilateral"
                          item-value="id"
                          return-object
                          :no-data-text="
                              fuentesMultilateral != null
                              ? 'Seleccione una fuente multilateral'
                              : 'No se han encontrado fuentes multilaterales'
                          "
                          menu-props="auto"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>                
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            autocomplete="off"
                            class="required"
                            dense
                            rows="2"
                            filled
                            color="blue-grey lighten-2"
                            v-model="datosItem.estrategia"
                            label="Nombre de la estrategia"
                            :rules="[
                                required('nombre de la estrategia'),
                                minLength('nombre de la estrategia', 5),
                                maxLength('nombre de la estrategia', 500)
                            ]"
                            maxlength="500"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>                
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            autocomplete="off"
                            class="required"
                            dense
                            rows="2"
                            filled
                            color="blue-grey lighten-2"
                            v-model="datosItem.resultados"
                            label="Resultados de la estrategia"
                            :rules="[
                                required('resultados de la estrategia'),
                                minLength('resultados de la estrategia', 5),
                                maxLength('resultados de la estrategia', 500)
                            ]"
                            maxlength="500"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>                
                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="fechaInicio"
                            label="Fecha de inicio"
                            :rules="[
                                required('fecha de inicio'),
                                minLength('fecha de inicio', 10),
                                dateFormat('fecha de inicio')
                            ]"
                            maxlength="10"
                            v-mask="'##/##/####'"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="fechaFinalizacion"
                            label="Fecha de finalización"
                            :rules="[
                                required('fecha de finalización'),
                                minLength('fecha de finalización', 10),
                                dateFormat('fecha de finalización'),
                                dateEqualGreatherThan('fecha de finalización', fechaInicio)
                            ]"
                            maxlength="10"
                            v-mask="'##/##/####'"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="montoMonedaUsd"
                            label="Monto en dólares"
                            prefix="USD"
                            :rules="[
                                required('monto en dólares'),
                                decimals10('monto en dólares')
                            ]"
                            maxlength="30"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="tipoCambio"
                            label="Tipo de cambio"
                            :rules="[
                                required('tipo de cambio'),
                                decimals10('tipo de cambio')
                            ]"
                            maxlength="30"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            dense
                            filled
                            :disabled="true"
                            autocomplete="off"
                            class="required"
                            color="blue-grey lighten-2"
                            v-model="datosItem.montoQuetzales"
                            label="Monto total quetzales"
                            maxlength="30"
                            prefix="GTQ"
                        ></v-text-field>
                    </v-col>

                </v-row>

                              


                
                <v-row>
                    <v-col cols="12" md="12" class="pb-0">
                        <v-spacer></v-spacer>
                        <!--:disabled="!validDocForm" type="submit"-->
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right ml-2"
                            type="submit"
                            :elevation="0"
                            :disabled="!validForm"
                            :loading="btnRegistroLoading"
                        >
                            {{accion===1 ? `Registrar estrategia` : `Actualizar estrategia`}}
                        </v-btn>

                        <v-btn
                            color="grey lighten-5"
                            elevation="0"
                            class="mb-2 float-right grey lighten-5"
                            @click="
                                dialogRegistro = false;
                                resetForm();
                            "
                            :disabled="btnRegistroLoading"
                        >
                            Cancelar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
    </v-dialog>


    <!--end:: modal registro-->


    <!--inicio:: dialog validacion -->
    <v-dialog v-model="dialogValidacion" width="500" transition="scroll-y-transition">
      <v-card>
        <v-card-title class="text-h5">
          Validar estrategia

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnValidacionLoading"
            persistent
            transition="scroll-y-transition"
            @click="dialogValidacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
         
            <v-row>
              <v-col cols="12">

                <p class="text-h6 black--text">
                  {{estrategia}}
                </p>

                <p class="text-h6">
                  ¿Está seguro de validar la estrategia seleccionada?
                </p>


                <p class="text-h6">
                  Una vez validada, ya no podrá actualizar su información!
                </p>
                
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">

                <v-btn
                  color="blue-grey darken-2"
                  text
                  class="float-right"
                  :loading="btnValidacionLoading"
                  type="submit"
                  @click="validarEstrategia"
                  
                >
                  Sí, validar
                </v-btn>

                <v-btn
                  color="blue-grey darken-2"
                  text
                  class="float-right"
                  @click="dialogValidacion = false"
                  :disabled="btnValidacionLoading"
                >
                  Cancelar
                </v-btn>

                
              </v-col>
            </v-row>
        </v-card-text>

      </v-card>
    </v-dialog>

    <!--fin:: dialog validacion -->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

</div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {OBTENER_ITEMS_CATALOGO, OBTENER_SUBITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";

import { OBTENER_ESTRATEGIAS_CNSC, OBTENER_ESTRATEGIAS_COOPERANTE_CNSC, REGISTRAR_ESTRATEGIA_CNSC, OBTENER_ESTRATEGIA_CNSC, ACTUALIZAR_ESTRATEGIA_CNSC, VALIDAR_ESTRATEGIA_CNSC } from "@/core/services/store/cooperantes/estrategias/estrategia.module";
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import moment from "moment";

export default {
    name: "AdminEstrategiasCooperantes",
    props: ['tipoUsuario'],
    components: {
        DialogLoader,
        SnackAlert
    },
    data() {
        return { 
          deshabilitarFuentes: false,
          fuenteCooperanteId: 0,
            btnRegistroLoading: false,
            validForm: false,
            dialogValidacion: false,
            btnValidacionLoading: false,
            tableItemsLoading:false,
            items:[],
            filtro: "",
            accion: 1,
            sectoresBeneficiados: [],
            dialogLoaderVisible: false,
            dialogLoaderText: "",
            nombreEstrategia: "",
            dialogRegistro: false,
            tiposSocioImplementador: [],
            ddTiposSocioImplementador: false,
            tipoFuenteCooperanteSeleccionada: {},
            tiposFuenteCooperante: [],
            fuenteBilateralSeleccionada: {},
            fuentesBilaterales: [],
            agenciaSeleccionada: {},
            agencias: [],
            ddAgenciasLoading:false,
            fuenteMultilateralSeleccionada: {},
            fuentesMultilateral: [],
            montoMonedaUsd:"",
            tipoCambio: "",
            fechaFinalizacion: "",
            fechaInicio: "",
            datosValidacion: {
              estadosId: 0,
              usuarioActualizacion: "admin",
            },
            estrategia: "",
            datosItem: {
                estrategia: "",
                resultados: "",
                fechaInicio: "",
                fechaFinalizacion: "",
                fuentesBilateralesId: 0,
                agenciasId: 0,
                montoUSD: 0,
                tipoCambio: 0,
                montoQuetzales:0,
                fuentesMultilateralesId: 0,
                tiposFuenteCooperanteId: 0,
                estadosId: 1,
                usuarioCreacion: "admin"
            },
            estado: "",
             estados: [
                { text: "Todos", value: null },
                { value: 1, text: "Activo" },
                { value: 2, text: "Inactivo" }
            ],
            ...validations
        }
    },

    methods: {

        filtroEstado(value) {
            // If this filter has no value we just skip the entire filter.
            if (!this.estado.value) {
                return true;
            }
            // Check if the current loop value (The calories value)
            // equals to the selected value at the <v-select>.
            return value === this.estado.text;
        },

        async obtenerItems(){
            return 0;
        },


        //Establecer el tipo de fuente y la fuente cooperante si el usuario es externo
        configurarInfoUsuario(){
          //console.log(this.tipoUsuario)
          if(this.tipoUsuario == "externo"){
            this.deshabilitarFuentes = true;
            //console.log(this.tiposFuenteCooperante)
            //console.log(this.currentUser.tipoFuenteCooperanteId)
            this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(item => item.id === this.currentUser.tipoFuenteCooperanteId);
            //console.log(this.tipoFuenteCooperanteSeleccionada)

            //Si es bilateral
            if(this.tipoFuenteCooperanteSeleccionada.id === 1){
              this.fuenteBilateralSeleccionada = this.fuentesBilaterales.find(item => item.id === this.currentUser.fuenteCooperanteId);
              this.fuenteCooperanteId = this.fuenteBilateralSeleccionada.id;
            }

            //Si es multilateral
            if(this.tipoFuenteCooperanteSeleccionada.id === 2){
              this.fuenteMultilateralSeleccionada = this.fuentesMultilateral.find(item => item.id === this.currentUser.fuenteCooperanteId);
              this.fuenteCooperanteId = this.fuenteMultilateralSeleccionada.id;
              //console.log(this.fuenteMultilateralSeleccionada)
              //console.log(this.fuentesMultilateral)
            }
          
            this.obtenerEstrategiasUsuario();
            
          } else {
            this.deshabilitarFuentes = false;
            this.obtenerEstrategias();
          }


 

        },



        //Obtener los tipos de fuente cooperante
        async obtenerTiposFuenteCooperante() {
            this.tiposFuenteCooperante = [];

            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposFuenteCooperante/all/1'})
                .then(res => {
                if (res.status === 200) {
                    this.tiposFuenteCooperante = res.data;
                }
                })
                .catch(() => {
                    this.tiposFuenteCooperante = [];
                });
            },


        async obtenerFuentesBilaterales() {
            this.fuentesBilaterales = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuentesBilaterales/all/1/0'})
            .then(res => {
            if (res.status === 200) {
                this.fuentesBilaterales = res.data;
            }
            })
            .catch(() => {
            this.fuentesBilaterales = [];
            });
        },


        //Obtener las agencias por fuente bilateral
        async obtenerAgenciasPorFuenteBilateral() {
            this.agencias = [];
            this.ddAgenciasLoading=true;
            await this.$store
            .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'Agencias/all/1', id: this.fuenteBilateralSeleccionada.id})
            .then(res => {
            if (res.status === 200) {
                this.ddAgenciasLoading=false;
                this.agencias = res.data;
            }
            })
            .catch(() => {
            this.ddAgenciasLoading=false;
            this.agencias = [];
            });
        },


        async obtenerFuentesMultilateral() {
          this.fuentesMultilateral = [];
          await this.$store.dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuenteMultiLateral/all/1'})
              .then(res => {
                if (res.status === 200) {
                    this.fuentesMultilateral = res.data;
                }
              })
              .catch(() => {
                  this.fuentesMultilateral = [];
              });
        },


        
      


        modalNuevo(){
            this.accion=1;
            this.resetForm();
            this.dialogRegistro=true;
        },

        resetForm(){
            this.agenciaSeleccionada = {};
            this.fuenteBilateralSeleccionada = {};
            this.fuenteMultilateralSeleccionada = {};

            this.configurarInfoUsuario();

            this.fechaInicio = "";
            this.fechaFinalizacion = "";
            this.montoMonedaUsd = "";
            this.tipoCambio = "";
            this.nombreEstrategia="";
            this.datosItem= {
                estrategia: "",
                resultados: "",
                fechaInicio: "",
                fechaFinalizacion: "",
                fuentesBilateralesId: 0,
                agenciasId: 0,
                montoUSD: 0,
                tipoCambio: 0,
                montoQuetzales:0,
                fuentesMultilateralesId: 0,
                tiposFuenteCooperanteId: 0,
                estadosId: 1,
                usuarioCreacion: "admin"
            };
        },


        async obtenerEstrategias(){
            
            this.tableItemsLoading = true;
            this.items = [];
            await this.$store
                .dispatch(OBTENER_ESTRATEGIAS_CNSC, {estadoId: 0, tipoFuenteCooperante:0, fuentesBilateralesId: 0, agenciasId: 0, fuentesMultilateralesId: 0, cooperanteId: 0})
                .then(res => {
                    if (res.status === 200) {
                        this.items = res.data;
                    }
                    this.tableItemsLoading = false;
                })
                .catch(() => {
                    this.items = [];
                    this.tableItemsLoading = false;
                });
        },

        async obtenerEstrategiasUsuario(){
          this.tableItemsLoading = true;
            this.items = [];
            await this.$store
                .dispatch(OBTENER_ESTRATEGIAS_COOPERANTE_CNSC, {estadoId: 0, tipoFuenteCooperante: this.tipoFuenteCooperanteSeleccionada.id, fuenteCooperanteId: this.fuenteCooperanteId})
                .then(res => {
                    if (res.status === 200) {
                        this.items = res.data;
                    }
                    this.tableItemsLoading = false;
                })
                .catch(() => {
                    this.items = [];
                    this.tableItemsLoading = false;
                });
        },


        


        //Registrar un socio implementador
        async registrarItem(){
            this.btnRegistroLoading = true;

            //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
            /* this.switchItemEstado
                ? (this.datosItem.estadosId = 1)
                : (this.datosItem.estadosId = 2); */


            
            this.datosItem.montoUSD = parseFloat(this.montoMonedaUsd);
            this.datosItem.tipoCambio = parseFloat(this.tipoCambio);


            this.datosItem.estadosId = 1;
            this.datosItem.tiposFuenteCooperanteId = this.tipoFuenteCooperanteSeleccionada.id;

            if(this.tipoFuenteCooperanteSeleccionada.id === 1){
              this.datosItem.fuentesBilateralesId = this.fuenteBilateralSeleccionada.id;
              this.datosItem.fuentesMultilateralesId = null;
              if(this.agenciaSeleccionada.id && this.agenciaSeleccionada.id >0){
                this.datosItem.agenciasId = this.agenciaSeleccionada.id;
              }
            } else {
              this.datosItem.fuentesBilateralesId = null;
              this.datosItem.agenciasId = null;
              this.datosItem.fuentesMultilateralesId = this.fuenteMultilateralSeleccionada.id;
            }


            this.datosItem.fechaInicio = moment(this.fechaInicio, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.datosItem.fechaFinalizacion = moment(this.fechaFinalizacion, "DD-MM-YYYY").format("YYYY-MM-DD");

            let dispatch = REGISTRAR_ESTRATEGIA_CNSC;

            //?Si accion es 1 se realiza el registro si es 2 la actualización
            if(this.accion === 2){
                dispatch = ACTUALIZAR_ESTRATEGIA_CNSC;
            } 

            await this.$store
            .dispatch(dispatch, this.datosItem)
            .then(res => {
                if (res.status === 200) {
                    //this.nombreInstitucion = this.datosItem.nombreInstitucion;
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
                    this.obtenerEstrategias();
                    this.dialogRegistro = false;

                    this.resetForm();
                    //this.tab = "tab-2";
                    //this.accion = 2;
                    this.tabContactosDisabled=false;
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnRegistroLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
                this.btnRegistroLoading = false;
            });
            
        },


        //!Obtener datos de un item especifico
        async obtenerDatosItem(Id) {
        
            this.dialogLoaderVisible = true;
            this.dialogLoaderText = "Obteniendo información...";
           
            
            this.btnRegistroText = `Actualizar`;
            //this.datosItem={};
            await this.$store
                .dispatch(OBTENER_ESTRATEGIA_CNSC, Id)
                .then(res => {
                //console.log(res);
                if (res.status === 200) {
                    this.datosItem = res.data
                    this.datosItem.estadosId === 1

                    this.tipoFuenteCooperanteSeleccionada.id = this.datosItem.tiposFuenteCooperanteId;
                    this.tipoFuenteCooperanteSeleccionada.nombreTipoFuente = this.datosItem.nombreTipoFuente;

                    this.nombreEstrategia = this.datosItem.estrategia;
                    
                    this.fechaInicio = moment(this.datosItem.fechaInicio, "YYYY-MM-DD").format("DD-MM-YYYY");
                    this.fechaFinalizacion = moment(this.datosItem.fechaFinalizacion, "YYYY-MM-DD").format("DD-MM-YYYY");

                    this.datosItem.montoUSD ? this.montoMonedaUsd = this.datosItem.montoUSD.toString() : this.montoMonedaUsd = "";
                    this.datosItem.tipoCambio ? this.tipoCambio = this.datosItem.tipoCambio.toString() : this.tipoCambio = "";

                    if(this.datosItem.tiposFuenteCooperanteId === 1){
                      this.fuenteBilateralSeleccionada =  this.fuentesBilaterales.find(item => item.id === this.datosItem.fuentesBilateralesId); 
                      this.datosItem.fuentesMultilateralesId = null;
                      this.fuenteMultilateralSeleccionada={};

                      if(this.datosItem.agenciasId){
                       this.obtenerAgenciasPorFuenteBilateral().then(()=> {
                          /* this.agenciaSeleccionada.id = this.datosItem.agenciasId; 
                          this.agenciaSeleccionada.nombreAgencia = this.datosItem.nombreAgencia; */
                          this.agenciaSeleccionada = this.agencias.find(item => item.id === this.datosItem.agenciasId);
                        });
                        
                      }
                    } else {
                      this.datosItem.fuentesBilateralesId = null;
                      this.datosItem.agenciasId = null;
                      this.fuenteMultilateralSeleccionada = this.fuentesMultilateral.find(item => item.id === this.datosItem.fuentesMultilateralesId);
                      
                    }
                    /* ? (this.switchItemEstado = true)
                    : (this.switchItemEstado = false); */
                    this.accion = 2;
                    this.dialogRegistro = true;
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`);
                }
                this.dialogLoaderVisible = false;
                })
                .catch(error => {
                   // console.log(error)
                    this.dialogLoaderVisible = false;
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema. ${error}`);
                
                });
        },


        mostrarDialoglValidar(estrategiaId, estrategia){
          this.datosValidacion.estrategiaId = estrategiaId;
          this.datosValidacion.estadosId = 3;
          this.estrategia = estrategia;
          this.dialogValidacion = true;
        },

        async validarEstrategia(){
         // console.log(this.datosValidacion);
           this.btnValidacionLoading=true;
            //this.datosCuentaDesembolsos.proyectosCNSId = this.id;
            //this.cuentaCondicionDesembolsoSeleccionada.id === 2 ? this.datosCuentaDesembolsos.proyectaDesembolsos = 0 : this.datosCuentaDesembolsos.proyectaDesembolsos = 1;  
            await this.$store
            .dispatch(VALIDAR_ESTRATEGIA_CNSC, {id: this.datosValidacion.estrategiaId, estadosId: this.datosValidacion.estadosId , usuarioActualizacion: this.datosValidacion.usuarioActualizacion})
            .then(res => {
                //this.dialogLoaderVisible = false;
                //console.log(res)
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerEstrategias();
                    this.dialogValidacion = false;
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                   
                }
                this.btnValidacionLoading=false;
            })
            .catch(error => {
                 this.btnValidacionLoading = false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        }

    },

    created(){
      //this.obtenerEstrategias();

      this.tableItemsLoading=true;


      this.obtenerTiposFuenteCooperante().then(()=>{

        this.obtenerFuentesBilaterales().then(()=>{

          this.obtenerFuentesMultilateral().then(()=>{

            this.configurarInfoUsuario();
          });
        });

      });
      
      

    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Inicio", route: "dashboard" },
        { title: "Administracion" },
        { title: "Estrategias cooperantes" }
        ]);
    }, 

    watch:{

        montoMonedaUsd : function(){
            this.datosItem.montoQuetzales = (this.montoMonedaUsd * this.tipoCambio);
        },

        tipoCambio : function() {
            this.datosItem.montoQuetzales = (this.montoMonedaUsd * this.tipoCambio);
        }

    },


    computed:{

      ...mapGetters(["currentUserPersonalInfo", "currentUser"]),

        headers() {
            return [
            {
                text: "Id",
                align: "d-none",
                sortable: false,
                value: "id"
            },
            {
                text: "Nombre estrategia",
                align: "start",
                sortable: true,
                value: "estrategia"
            },
            {
                text: "País/Organización/Agencia",
                align: "start",
                sortable: true,
                value: ""
            },
             {
                text: "Tipo fuente cooperante",
                align: "start",
                sortable: true,
                value: "nombreTipoFuente"
            }, 
            {
                text: "Estado",
                align: "start",
                sortable: true,
                value: "estado",
                filter: this.filtroEstado
            },
            {
                text: "Acciones",
                align: "start",
                sortable: false,
                value: ""
            }
        ]
        }, 
    }

}
</script>
<style lang="">
    
</style>